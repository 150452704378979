<!--
 * @Description: 创建开票信息
 * @Author: ChenXueLin
 * @Date: 2021-10-21 16:17:56
 * @LastEditTime: 2022-10-13 15:50:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 开票信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">开票信息</div>
            <el-form
              label-width="140px"
              :inline="true"
              :model="detailInfo"
              :rules="rules"
              ref="detailInfo"
            >
              <el-form-item label="企业类型" prop="bssType">
                <e6-vr-select
                  v-model="detailInfo.bssType"
                  :data="bssTypeTypeEnum"
                  :disabled="!!detailInfo.billingCorpId"
                  @change="bssTypeChange"
                  placeholder="企业类型"
                  title="企业类型"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="开票名称" prop="billingCorpName">
                <e6-vr-select
                  title="开票名称"
                  :data="billingCorpNameEnum"
                  v-if="detailInfo.bssType == 2"
                  :disabled="!!detailInfo.billingCorpId"
                  virtual
                  clearable
                  remote
                  :is-title="true"
                  placeholder="开票名称"
                  v-model="detailInfo.billingCorpName"
                  :loading="billingCorpNameLoading"
                  @filterChange="handleFilter"
                  @change="billingCorpNameChange"
                  @clear="billingCorpNameChange"
                  :props="{
                    id: 'billingCorpName',
                    label: 'billingCorpName'
                  }"
                ></e6-vr-select>
                <el-input
                  v-else
                  v-model.trim="detailInfo.billingCorpName"
                  placeholder="开票名称"
                  title="开票名称"
                  maxlength="64"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="!!detailInfo.billingCorpId"
                label="客户名称"
                prop="bankNumber"
              >
                <el-input
                  disabled
                  placeholder="客户名称"
                  v-model.trim="detailInfo.customerName"
                ></el-input>
              </el-form-item>
              <el-form-item label="纳税人类别" prop="invoicePeopleType">
                <e6-vr-select
                  v-model="detailInfo.invoicePeopleType"
                  :data="invoicePeopleTypeEnum"
                  @change="invoicePeopleTypeChange"
                  placeholder="纳税人类别"
                  title="纳税人类别"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="开票类型" prop="invoiceType">
                <e6-vr-select
                  v-model="detailInfo.invoiceType"
                  :data="invoiceTypeEnumComputed"
                  @change="clearValidateInvoceForm"
                  placeholder="开票类型"
                  title="开票类型"
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                ></e6-vr-select>
              </el-form-item>

              <el-form-item label="纳税人识别编号" prop="taxNo">
                <el-input
                  placeholder="纳税人识别编号/个人填手机号"
                  :disabled="
                    (!!detailInfo.billingCorpId && detailInfo.bssType == 2) ||
                      disabledTaxNo
                  "
                  v-model.trim="detailInfo.taxNo"
                  maxlength="32"
                ></el-input>
              </el-form-item>

              <el-form-item label="开户行名称" prop="bankName">
                <el-input
                  placeholder="开户行名称"
                  v-model.trim="detailInfo.bankName"
                  maxlength="128"
                ></el-input>
              </el-form-item>

              <el-form-item label="开户行账号" prop="bankNumber">
                <el-input
                  placeholder="开户行账号"
                  v-model.trim="detailInfo.bankNumber"
                  maxlength="128"
                ></el-input>
              </el-form-item>

              <el-form-item label="公司电话" prop="phone">
                <el-input
                  placeholder="公司电话"
                  v-model.trim="detailInfo.phone"
                  maxlength="128"
                ></el-input>
              </el-form-item>

              <el-form-item label="开户行地址" prop="address">
                <el-input
                  placeholder="开户行地址"
                  v-model.trim="detailInfo.address"
                  maxlength="128"
                ></el-input>
              </el-form-item>

              <el-form-item
                prop="fileId"
                label="开票附件"
                class="contract-file"
              >
                <uploadOBS
                  v-model="detailInfo.fileId"
                  :fileType="1"
                  :limit="1"
                  buttonText="点击上传合同文件"
                  :uploadFileList.sync="contractFileList"
                >
                </uploadOBS>
              </el-form-item>
            </el-form>
          </div>
          <!-- 开票信息 end -->
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack()">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import { printError } from "@/utils/util";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  bankNoValid,
  telValid,
  mobileValid,
  TaxpayerIdentificationNumberValid
} from "@/utils/validate";
import {
  getInvoiceDetail,
  updateInvoice,
  createInvoice,
  findDownList,
  invoiceDataDownListFormTianyancha
} from "@/api";

// 按钮ID常量

export default {
  name: "AddCustomerInvoice",
  mixins: [base, goBack],
  components: { uploadOBS },
  data() {
    return {
      billingCorpNameLoading: false,
      disabledTaxNo: false,
      detailInfo: {
        corpId: 0, // 客户id 客户详情里面编辑开票信息时用到
        bssType: "", //企业类型
        invoicePeopleType: "", //纳税人类别
        invoiceType: "1", //开票类型
        billingCorpName: "", //开票名称
        customerName: "", // 开票客户名称(crm)
        taxNo: "", //税号
        bankName: "", //开户行名称
        bankNumber: "", //开户行账号
        address: "", //客户行地址
        phone: "", // 公司电话
        fileId: "", //附件id
        fileCode: "" //附件code
      }, //详情
      bssTypeTypeEnum: [], // 企业类型
      invoicePeopleTypeEnum: [], // 纳税人类别
      invoiceTypeEnum: [], // 开票类型
      billingCorpNameEnum: [], // 开票名称
      columnData: [
        {
          fieldName: "type",
          display: true,
          fieldLabel: "问题类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "问题描述",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "处理方案",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "关联单据",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "关联单据类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "维修日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      contractFileList: [], //附件列表
      isEditContract: false // 是否是编辑
    };
  },
  computed: {
    invoiceTypeEnumComputed() {
      let target = this.invoiceTypeEnum.find(item => item.codeValue === "2");
      if (!target) {
        return this.invoiceTypeEnum;
      }
      if (this.detailInfo.invoicePeopleType === "3") {
        target.disabled = false;
      } else {
        target.disabled = true;
      }
      return [...this.invoiceTypeEnum];
    },
    rules() {
      const getRequiredRule = msg => {
        return {
          required: true,
          message: msg,
          trigger: ["blur", "change"]
        };
      };
      let illegalCharacter = /[[ _`!@#$%^&*+=|{}’:;’,.<>/\\?！@#¥%……&*——+|{}【】‘；：”“’。，、？\]|\n|\r|\t]+$/;
      let validateIllegalCharacter = (rule, value, callback) => {
        // 企业类型为个人时增加的校验规则
        if (value && illegalCharacter.test(value)) {
          return callback(new Error("输入内容含有非法特殊字符"));
        }
        callback();
      };
      const invoceRules = {
        bssType: [getRequiredRule("请选择企业类型")],
        billingCorpName: [getRequiredRule("请输入开票名称")],
        taxNo: [
          getRequiredRule("请输入纳税人识别编号"),
          {
            validator: TaxpayerIdentificationNumberValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        invoiceType: [getRequiredRule("请选择开票类别")],
        invoicePeopleType: [getRequiredRule("纳税人类别")],
        bankNumber: [
          {
            validator: bankNoValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        phone: [
          {
            validator: telValid.validator(),
            trigger: ["blur", "change"]
          }
        ]
      };
      let { invoicePeopleType, bssType } = this.detailInfo;
      // 个人普票不要求填纳税人识别编号 // 20220712 任务 40650 删除此逻辑
      // if (invoicePeopleType == 1 && invoiceType == 1) {
      //   delete invoceRules.taxNo;
      // }
      // 一般纳税人要求信息全填
      if (invoicePeopleType == 3) {
        invoceRules.bankName = [getRequiredRule("请输入开户行名称")];
        invoceRules.bankNumber = [getRequiredRule("请输入开户行账号")];
        invoceRules.phone.push(getRequiredRule("请输入公司电话"));
        invoceRules.address = [getRequiredRule("请输入开户行地址")];
        invoceRules.fileId = [getRequiredRule("请上传开票附件")];
      }
      if (bssType == 1) {
        invoceRules.billingCorpName.push({
          validator: validateIllegalCharacter,
          trigger: ["blur", "change"]
        });
        invoceRules.taxNo.splice(1, 1, {
          validator: mobileValid.validator(),
          trigger: ["blur", "change"]
        });
      }
      return invoceRules;
    }
  },
  watch: {},
  created() {
    this.initData();
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    // 初始化表单
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.detailInfo.billingCorpId = hasParamsRoute.params.id;
      if (this.detailInfo.billingCorpId) {
        this.contractFileList = [];
        this.getInvoiceDetail();
      } else {
        if (this.$refs.detailInfo) this.$refs.detailInfo.resetFields();
      }
    },
    // 模糊查询过滤
    handleFilter: _.debounce(async function(val) {
      this.handleBillingCorpNameChange(val);
    }, 300),
    handleBillingCorpNameChange(val) {
      if (val) {
        this.invoiceDataDownListFormTianyancha(val);
        this.detailInfo.billingCorpName = "";
      }
    },
    // 选择天眼查开票信息 回显纳税人识别号
    billingCorpNameChange(val, node) {
      if (val && node) {
        this.detailInfo.taxNo = node.taxNo;
        this.disabledTaxNo = !!node.taxNo;
      } else {
        this.detailInfo.taxNo = "";
        this.disabledTaxNo = false;
      }
    },
    // 企业类型发生改变
    bssTypeChange(val) {
      if (val == 1) {
        this.detailInfo.billingCorpName = "";
        this.detailInfo.taxNo = "";
        this.disabledTaxNo = false;
      }
    },
    // 天眼查获取开票名称列表
    async invoiceDataDownListFormTianyancha(val) {
      try {
        this.billingCorpNameLoading = true;
        let res = await invoiceDataDownListFormTianyancha({
          billingCorpName: val
        });
        if (res.code === "OK") {
          this.billingCorpNameEnum = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          this.billingCorpNameEnum = [];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.billingCorpNameLoading = false;
      }
    },
    //获取开票信息详情
    async getInvoiceDetail() {
      try {
        this.loading = true;
        let res = await getInvoiceDetail({
          billingCorpId: this.detailInfo.billingCorpId
        });
        let data = res.data;
        if (!data) {
          return;
        }
        data.bssType += "";
        data.invoicePeopleType += "";
        data.invoiceType += "";
        this.billingCorpNameEnum = [
          {
            billingCorpName: data.billingCorpName
          }
        ];
        this.detailInfo = data;
        if (this.detailInfo.fileId) {
          this.contractFileList = [
            {
              fileId: this.detailInfo.fileId,
              name: this.detailInfo.fileName,
              fileUrl: this.detailInfo.fileUrl
            }
          ];
        } else {
          this.detailInfo.fileId = "";
        }
        // 清除校验结果
        if (this.$refs.detailInfo) {
          this.$nextTick(() => {
            this.$refs.detailInfo.clearValidate();
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取枚举信息
    async initData() {
      try {
        //获取枚举数据
        let promiseList = [
          findDownList(["bssType", "invoicePeopleType", "invoiceType"])
        ];
        let [findDownListRes] = await Promise.all(promiseList);
        this.bssTypeTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.bssType"
        });
        this.invoicePeopleTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.invoicePeopleType"
        });
        this.invoiceTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.invoiceType"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 更新form数据
    initInvoiceData(data = {}, corpBase) {
      this.detailInfo = {
        ...this.detailInfo,
        ...data,
        corpId: corpBase.corpId
      };
      this.detailInfo.bssType += "";
      this.detailInfo.invoicePeopleType += "";
      this.detailInfo.invoiceType += "";
      this.billingCorpNameEnum = [
        {
          billingCorpName: data.billingCorpName
        }
      ];
    },
    // 清空表单校验
    clearValidateInvoceForm() {
      this.$nextTick(() => {
        this.$refs.detailInfo.clearValidate();
      });
    },
    // 纳税人类别改变
    invoicePeopleTypeChange() {
      this.detailInfo.invoiceType = "1";
      this.clearValidateInvoceForm();
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.detailInfo.validate(valid => {
        if (valid) {
          this.editInvoice();
        }
      });
    },
    //编辑开票信息请求
    async editInvoice() {
      try {
        this.loading = true;
        let handleapi = createInvoice;
        if (this.detailInfo.billingCorpId) {
          handleapi = updateInvoice;
        }
        let res = await handleapi(this.detailInfo);
        if (res.code == "OK") {
          this.$message.success(
            res.msg && res.msg.indexOf("审批中") !== -1
              ? "已进入飞书审批流程"
              : res.msg
          );
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-title {
  margin-bottom: 20px;
}
.baseInfo-box {
  width: 100%;
  .el-form {
    .el-input {
      width: 220px;
    }
    .e6-vr-select {
      width: 220px;
    }
  }
}
.contract-file {
  // height:28px;
  .el-form-item__content {
    display: flex;
  }
  .contract-upload {
    width: 220px;
  }
  .contract-upload-success {
    .el-upload {
      display: none;
    }
    .el-upload-list__item {
      margin-top: 2px;
    }
  }
}
</style>
